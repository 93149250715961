var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"title":_vm.promotionModal.type == 'edit' ? 'Editar Promocion': 'Crear Promocion',"modal-class":"modal-primary","hide-footer":"","size":"lg","loading":""},on:{"hidden":_vm.close},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[(true)?_c('validation-observer',{ref:"checkForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-row',[_c('b-col',{staticClass:"mt-1",attrs:{"cols":"6"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between text-white"},[_c('label',{attrs:{"for":"name"}},[_vm._v("Nombre")])]),_c('validation-provider',{attrs:{"name":"name","vid":"name","rules":"max:255|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{class:{ 'border-danger rounded': errors[0] },attrs:{"type":"text","placeholder":"Ingrese el nombre de la promoción","disabled":_vm.disableAll},model:{value:(_vm.promotion.name),callback:function ($$v) {_vm.$set(_vm.promotion, "name", $$v)},expression:"promotion.name"}})],1)]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"6"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between text-white"},[_c('label',{attrs:{"for":"cost"}},[_vm._v("Precio")])]),_c('validation-provider',{attrs:{"name":"cost","vid":"cost","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',[_vm._v("S/.")])]},proxy:true}],null,true)},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('###.###.##'),expression:"'###.###.##'"}],class:{ 'border-danger rounded': errors[0] },attrs:{"placeholder":"Ingrese el costo de la promoción","disabled":_vm.disableAll},model:{value:(_vm.promotion.cost),callback:function ($$v) {_vm.$set(_vm.promotion, "cost", $$v)},expression:"promotion.cost"}})],1)]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between text-white"},[_c('label',{attrs:{"for":"name"}},[_vm._v("Descripción")])]),_c('validation-provider',{attrs:{"name":"name","vid":"name","rules":_vm.type === 'optional' ? 'max:255' : 'max:255|required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-textarea',{class:{ 'border-danger rounded': errors[0] },attrs:{"type":"text","placeholder":"Ingrese la descripción de la promoción","disabled":_vm.disableAll,"rows":3},model:{value:(_vm.promotion.description),callback:function ($$v) {_vm.$set(_vm.promotion, "description", $$v)},expression:"promotion.description"}})],1)]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between text-white"},[_c('label',{attrs:{"for":"image"}},[_vm._v("Imagen")])]),_c('validation-provider',{attrs:{"name":"image","vid":"image","rules":_vm.type === 'optional' ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-file',{class:{ 'border-danger rounded': errors[0] },attrs:{"type":"text","placeholder":"Ingrese una imagen","disabled":_vm.disableAll},model:{value:(_vm.promotion.image),callback:function ($$v) {_vm.$set(_vm.promotion, "image", $$v)},expression:"promotion.image"}})],1)]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"mx-1",attrs:{"variant":"light","disabled":_vm.disableAll},on:{"click":_vm.close}},[_vm._v(" Cancelar ")]),(!_vm.loading)?_c('b-button',{attrs:{"type":"submit","variant":"primary","disabled":invalid || _vm.disableAll},on:{"click":_vm.registerPromotion}},[_vm._v(" "+_vm._s(_vm.promotionModal.type == "edit" ? 'Actualizar':'Guardar')+" ")]):_c('b-button',{attrs:{"variant":"primary","disabled":""}},[_c('b-spinner',{attrs:{"small":""}}),_vm._v(" Cargando... ")],1)],1)])],1)]}}],null,false,4066338340)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }